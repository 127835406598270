import React, { useState } from 'react';
import { colors } from '../../styles/atoms/colors';
// eslint-disable-next-line import/no-named-default
import { default as IconAtom } from '../../styles/atoms/icons';
import Image from '../../utils/OptimizedImage';
import { Button, Container, Icon } from './styles/InlineVideo.styled';

const InlineVideo = ({
  className,
  videoId,
  videoImage,
  iconColor = colors.base.white,
  iconBgColor = colors.primary[500],
}) => {
  const [play, setPlay] = useState(false);

  return (
    <Container className={className}>
      {!play ? (
        <Button type="button" onClick={() => setPlay(true)} aria-label="Play">
          <Icon style={{ backgroundColor: iconBgColor }}>
            <IconAtom id="FaPlay" size={25} color={iconColor} />
          </Icon>
          {videoImage?.gatsbyImageData ? (
            <Image
              image={videoImage?.gatsbyImageData}
              alt={videoImage?.alt}
              title={videoImage?.title}
            />
          ) : (
            videoImage?.url && (
              <Image
                src={videoImage?.url}
                alt={videoImage?.alt}
                title={videoImage?.title}
              />
            )
          )}
        </Button>
      ) : (
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=0&autohide=1&showinfo=0&autoplay=${
            play ? 1 : 0
          }`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={
            play
              ? 'active_iframe featured_video'
              : 'hidden_iframe featured_video'
          }
        />
      )}
    </Container>
  );
};

export default InlineVideo;
