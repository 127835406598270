import styled from 'styled-components';

import shadow from '../../../styles/atoms/shadows';

export const Container = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

export const Icon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  z-index: 100;
  ${shadow('lg')}

  svg {
    transform: translateX(3px);
  }
`;
