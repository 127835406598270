import { isBlockquote, isHeading } from 'datocms-structured-text-utils';
import React from 'react';
import { renderNodeRule } from 'react-datocms';
import InlineVideo from '../../../molecules/InlineVideo/InlineVideo';
import WaveBg from '../../../molecules/Section/SectionBg/WaveBg';
import { colors } from '../../../styles/atoms/colors';
import Link from '../../../utils/Link';
import Img from '../../../utils/OptimizedImage';
import { getYoutubeId } from '../../../utils/getYoutubeId';
import { toCamelCase } from '../../../utils/helperFunctions';
import { formatLink } from '../../../utils/tableOfContents';
import SubscribeForm from '../../Forms/SubscribeForm';

export const renderBlock = ({ record }) => {
  switch (record.__typename) {
    case 'DatoCmsImage':
      return (
        <figure>
          <Img
            image={record.image.gatsbyImageData}
            alt={record.image.alt}
            width={record.image.width}
            height={record.image.height}
          />
          {record.image.title && <figcaption>{record.image.title}</figcaption>}
        </figure>
      );

    case 'DatoCmsNewsletter':
      return (
        <SubscribeForm
          block
          heading={record.heading}
          hubspotFormId={record.hubspotFormId}
        />
      );

    case 'DatoCmsVideo':
      return (
        <InlineVideo
          className="video"
          videoId={getYoutubeId(record.video.url)}
          videoImage={{
            url: record.video.thumbnailUrl.replace(
              'hqdefault',
              'maxresdefault'
            ),
          }}
        />
      );

    default:
      return <></>;
  }
};

export const renderLinkToRecord = ({ record, children }) => {
  switch (record.__typename) {
    case 'DatoCmsBlogPost':
      return <Link to={`/blog/${record.slug}`}>{children}</Link>;

    case 'DatoCmsBlogCategory':
      return (
        <Link to={`/blog?topic=${toCamelCase(record.name)}#posts`}>
          {children}
        </Link>
      );

    case 'DatoCmsCaseStudy':
      return <Link to={`/case-studies/${record.slug}`}>{children}</Link>;

    case 'DatoCmsPressRelease':
      return <Link to={`/newsroom/${record.slug}`}>{children}</Link>;

    case 'DatoCmsIntegrationPage':
      return <Link to={`/integrations/${record.slug}`}>{children}</Link>;

    default:
      return <Link to={`/${record.slug}`}>{children}</Link>;
  }
};

export const renderInlineRecord = ({ record }) => {
  switch (record.__typename) {
    case 'DatoCmsBlogPost':
      return <Link to={`/blog/${record.slug}`}>{record.title}</Link>;

    case 'DatoCmsBlogCategory':
      return (
        <Link to={`/blog?topic=${toCamelCase(record.name)}#posts`}>
          {record.name}
        </Link>
      );

    case 'DatoCmsCaseStudy':
      return <Link to={`/case-studies/${record.slug}`}>{record.title}</Link>;

    case 'DatoCmsPressRelease':
      return <Link to={`/newsroom/${record.slug}`}>{record.title}</Link>;

    case 'DatoCmsIntegrationPage':
      return <Link to={`/integrations/${record.slug}`}>{record.title}</Link>;

    default:
      return <Link to={`/${record.slug}`}>{record.metaTags?.title}</Link>;
  }
};

export const customNodeRules = [
  renderNodeRule(isHeading, ({ node, children, key }) => {
    const HeadingTag = `h${node.level}`;
    const name = formatLink(node.children.map(child => child.value).join(''));

    return (
      <HeadingTag key={key} name={name}>
        {children}
      </HeadingTag>
    );
  }),
  renderNodeRule(isBlockquote, ({ node, children, key }) => {
    return (
      <blockquote key={key}>
        <WaveBg
          className="wave"
          width={261}
          height={141.47}
          fill="none"
          stroke={colors.primary[100]}
          strokeWidth={30}
        />
        <div className="content">
          <div className="quote">{children}</div>
          {node.attribution && (
            <footer>
              <div>{node.attribution}</div>
            </footer>
          )}
        </div>
      </blockquote>
    );
  }),
];
